<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ updateMode == false ? $t('message.addStandardDataFilter') : $t('message.updateStandardDataFilter') + ": " + dataFilterName }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="dataFilterForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.methodTitle') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.method_title"
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.method_title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.method') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.method"
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.method = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.filterTitle') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.filter_title"
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.filter_title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.conditionField') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.condition_field"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.condition_field = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.model') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.model"
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.model = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.valueDisplayField') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.value_display_field"
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.value_display_field = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.valueField') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.value_field"
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.value_field = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.valueModel') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.value_model"
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.value_model = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.valuePlaceholder') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="Condition.value_placeholder"
                                    :rules="[...validationRules.required, ...validationRules.variable]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Condition.value_placeholder = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addStandardDataFilter()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveStandardDataFilter()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from "Api";
import {mapGetters} from "vuex";

export default {
    name: "AddStandardDataFilter",
    props: ['dataFilterId','dataFilterName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            Condition: {
                id: null,
                condition_field: null,
                filter_title: null,
                method: null,
                method_title: null,
                model: null,
                value_display_field: null,
                value_field: null,
                value_model: null,
                value_placeholder: null
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            // rules: {
            //     conditionField: v => !!v || this.$t('message.required'),
            //     filterTitle: v => !!v || this.$t('message.required'),
            //     method: v => !!v || this.$t('message.required'),
            //     methodTitle: v => !!v || this.$t('message.required'),
            //     model: v => !!v || this.$t('message.required'),
            //     valueDisplayField: v => !!v || this.$t('message.required'),
            //     valueField: v => !!v || this.$t('message.required'),
            //     valueModel: v => !!v || this.$t('message.required'),
            //     valuePlaceholder: v => !!v || this.$t('message.required')
            // },
            validForm: true,
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        addStandardDataFilter() {
            if(this.$refs.dataFilterForm.validate()){
                this.loading.add = true
                api
                    .post("/admin/data-filters",{
                        filter: this.Condition
                    })
                    .then(response => {
                        if(response.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.dataFilterAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.dataFilterNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('message.errors.dataFilterNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.dataFilterNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        getStandardDataFilterToUpdateById() {
            return new Promise((resolve, reject) => {
                api
                    .get('/admin/data-filters/' + this.dataFilterId)
                    .then(response => {
                        let data = response.data.data[0]
                        this.Condition.id = data.Condition.id
                        this.Condition.condition_field = data.Condition.condition_field
                        this.Condition.filter_title = data.Condition.filter_title
                        this.Condition.method = data.Condition.method
                        this.Condition.method_title = data.Condition.method_title
                        this.Condition.model = data.Condition.model
                        this.Condition.value_display_field = data.Condition.value_display_field
                        this.Condition.value_field = data.Condition.value_field
                        this.Condition.value_model = data.Condition.value_model
                        this.Condition.value_placeholder = data.Condition.value_placeholder

                        resolve('done')
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        resetDataFilterForm() {
            this.Condition.id = null
            this.Condition.condition_field = null
            this.Condition.filter_title = null
            this.Condition.method = null
            this.Condition.method_title = null
            this.Condition.model = null
            this.Condition.value_display_field = null
            this.Condition.value_field = null
            this.Condition.value_model = null
            this.Condition.value_placeholder = null
        },
        saveStandardDataFilter () {
            if(this.$refs.dataFilterForm.validate()){
                this.loading.save = true
                api
                    .put("/admin/data-filters/" + this.dataFilterId,{
                        filter: this.Condition
                    })
                    .then(response => {
                        if(response.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.dataFilterUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.dataFilterNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('message.errors.dataFilterNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.dataFilterNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getStandardDataFilterToUpdateById(this.dataFilterId)
                        .then( () => {
                            this.loading.get = false
                        })
                        .catch(() => {
                            this.loading.get = false
                        })
                } else {
                    this.resetDataFilterForm()
                }
            } else {
                this.resetDataFilterForm()
            }
            this.edit_dialog = value
        }
    },
}
</script>

<style scoped>

</style>